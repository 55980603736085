@use 'sass:map';
@use "../../../styles/@tokens" as tokens;

$suitable-card-max-width: 320px;
$suitable-card-min-width-mobile: 320px;
$suitable-card-hover-box-shadow: tokens.$shadow-300;

$suitable-card-image-wrapper-aspect-ratio: 22 / 9;

$suitable-card-link-color: tokens.$text-primary-default;

$suitable-card-body-padding: tokens.$space-700 tokens.$space-700 tokens.$space-800;
$suitable-card-heading-margin-bottom: tokens.$space-500;
$suitable-card-heading-font-size: map.get(tokens.$body-large-text-regular, 'mobile', 'font-size');
$suitable-card-heading-font-weight: map.get(tokens.$body-large-text-bold, 'mobile', 'font-weight');
$suitable-card-heading-line-height: map.get(tokens.$body-large-text-regular, 'mobile', 'line-height');
$suitable-card-heading-hover-color: tokens.$action-primary-default;
$suitable-card-active-heading-color: tokens.$action-primary-active;
$suitable-card-content-margin-bottom: tokens.$space-500;
$suitable-card-footer-color: tokens.$action-primary-default;
