@use 'sass:map';
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;
@use '../../styles/@tokens' as tokens;

.Introduction__container {
  max-width: 800px;
  padding-block: tokens.$space-700;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    padding-block: tokens.$space-900;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    padding-block: tokens.$space-1200;
  }
}

.Introduction__review {
  display: flex;
  flex-direction: column;
  gap: tokens.$space-700;
  align-items: center;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    flex-direction: row;
  }
}

.Introduction__reviewBlock {
  flex: 1;
}

.Introduction__whatIsReview {
  gap: tokens.$space-700;
  padding: tokens.$space-600 tokens.$space-700 tokens.$space-600 tokens.$space-700;
  border-radius: tokens.$radius-300;
  background-color: tokens.$background-cover;
}

.Introduction__jobPortalLogo {
  width: 160px;
  height: 80px;
}

.Introduction__brandPromo {
  display: flex;
  flex-direction: row;
  gap: tokens.$space-800;
  justify-content: center;
  padding: tokens.$space-700 tokens.$space-800;
}