@use "styles/@tokens" as tokens;
@use "sass:map";

.ClampLines__content {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.ClampLines__toggle {
  padding: tokens.$space-400 tokens.$space-0 tokens.$space-0;
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  color: tokens.$action-link-primary-default;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.ClampLines__toggle:hover,
.ClampLines__toggle:focus {
  color: tokens.$action-link-primary-hover;
}

.ClampLines__toggle:active {
  color: tokens.$action-link-primary-active;
}
