@use "theme";
@use '../../../styles/@tokens' as tokens;
@use "@lmc-eu/spirit-web/scss/tools/breakpoint";
@use "../../../styles/tools";

.SuitableCard {
  position: relative;
  min-width: theme.$suitable-card-min-width-mobile;
  overflow: hidden;
  @include tools.card ();

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    min-width: auto;
  }

  &:hover,
  &:focus {
    box-shadow: theme.$suitable-card-hover-box-shadow;
  }
}

.SuitableCard__link {
  color: theme.$suitable-card-link-color;
}

.SuitableCard__link:hover {
  text-decoration: none;
  color: theme.$suitable-card-link-color;
}

.SuitableCard__image {
  object-fit: cover;
}

.SuitableCard__imageWrapper {
  position: relative;
  aspect-ratio: theme.$suitable-card-image-wrapper-aspect-ratio;
}

.SuitableCard__body {
  padding: theme.$suitable-card-body-padding;
}

.SuitableCard__heading {
  margin-bottom: theme.$suitable-card-heading-margin-bottom;
  font-weight: theme.$suitable-card-heading-font-weight;
  font-size: theme.$suitable-card-heading-font-size;
  line-height: theme.$suitable-card-heading-line-height;
  color: theme.$suitable-card-link-color;
}

.SuitableCard:hover .SuitableCard__heading,
.SuitableCard:focus .SuitableCard__heading {
  color: theme.$suitable-card-heading-hover-color;
}

.SuitableCard:active .SuitableCard__heading {
  color: theme.$suitable-card-active-heading-color;
}

.SuitableCard__content {
  margin-bottom: theme.$suitable-card-content-margin-bottom;
}

.SuitableCard__footer {
  color: theme.$suitable-card-footer-color;
}
