@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tools';

.Comment {
  display: flex;
  padding: tokens.$space-600 tokens.$space-600 tokens.$space-700 tokens.$space-400;

  @include tools.card ();
}

.Comment--withBackground {
  background-color: tokens.$background-cover;
}

.Comment + hr {
  margin-right: -1 * tokens.$space-600;
  margin-left: -1 * tokens.$space-400;
}

.Comment > svg {
  flex-shrink: 0;
  margin-right: tokens.$space-400;
}

.Comment__text {
  display: block;
  white-space: pre-line;
}